<script lang="ts" setup>
import { requestOtpCode, validateOtp } from "~/lib/apiClient";

const route = useRoute();
const auth = useAuth()

const otpSent = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const seconds = ref<number>(120);
const errors = ref<{ nif?: string; otp?: string }>();

const nifInputRef = ref<string>();
const otpInputRef = ref<string>();

async function requestCode(): Promise<void> {
    if (!validateForm()) {
        return;
    }
    seconds.value = 120;
    isLoading.value = true;
    otpSent.value = false;

    const otpSentOrError = await requestOtpCode(nifInputRef.value || "");

    if (otpSentOrError.isLeft()) {
        isLoading.value = false;
        return;
    }

    isLoading.value = false;
    otpSent.value = true;
    countDownSeconds();
}

function validateForm(): boolean {
    errors.value = {};
    if (!nifInputRef.value) {
        errors.value = { ...errors.value, nif: "Adicione um NIF" };
    }

    if (otpSent.value && !otpInputRef.value) {
        errors.value = { ...errors.value, otp: "Adicione o código que foi enviado por email" };
    }

    if (errors.value?.nif || errors.value?.otp) return false;

    return true;
}

async function validateOtpCode(): Promise<void> {
    if (!validateForm()) {
        return;
    }
    const voidOrError = await validateOtp(nifInputRef.value || "", otpInputRef.value || "");

    if (voidOrError.isLeft()) {
        alert(voidOrError.value.message);
        return;
    }

    login(nifInputRef.value!);
}

async function login(nif: string) {   
    
    auth.login(nif)
    navigateTo({ path: route.query.page as string, replace: true });
}

function countDownSeconds() {
    const secondsIntervalId = setInterval(() => {
        seconds.value = seconds.value - 1;
        if (seconds.value === 0) {
            clearInterval(secondsIntervalId);
        }
    }, 1000);
}
</script>

<template>
    <section>
        <div
            class="section-content flex flex-col justify-center lg:flex-row items-center lg:items-start lg:justify-between lg:pt-[12rem] pt-[9rem] pb-10"
        >
            <div>
                <h1 class="w-52 lg:w-[22rem]">Iniciar Sessão</h1>
                <p class="my-4 w-52 text-blue-900 lg:text-[1.6rem] lg:w-80 font-medium">
                    Adicione o seu NIF para iniciar sessão.
                </p>
            </div>
            <div>
                <h2 class="text-blue-900 p-8 lg:w-96 lg:p-0 text-center">Login</h2>

                <p class="text-center my-3 text-grey-700">Irá receber um código por email.</p>

                <form class="flex flex-col">
                    <div>
                        <input
                            type="text"
                            id="nif"
                            placeholder="NIF"
                            class="custom-input bg-grey-400 w-60 h-7"
                            v-model="nifInputRef"
                        />
                    </div>
                    <span v-if="errors?.nif" class="text-center text-base w-full mt-4 text-error">
                        {{ errors?.nif }}
                    </span>

                    <div v-if="otpSent">
                        <input
                            type="text"
                            id="otp"
                            placeholder="Código OTP"
                            class="custom-input bg-grey-400 w-60 h-7 mt-8"
                            v-model="otpInputRef"
                        />
                    </div>

                    <span v-if="errors?.otp" class="text-center text-base w-full mt-4 text-error">
                        {{ errors?.otp }}
                    </span>

                    <VButton
                        color="bg-primary"
                        v-if="!isLoading"
                        class="mt-4"
                        :text="otpSent ? 'Entrar' : 'Enviar Código'"
                        :event-click="otpSent ? validateOtpCode : requestCode"
                    />
                    <div
                        v-if="isLoading"
                        class="mt-8 h-14 rounded-full text-white font-medium bg-grey-600 flex items-center justify-center"
                    >
                        Loading...
                    </div>

                    <button v-if="otpSent" type="button" @click="requestCode" :disabled="seconds !== 0" class="mt-8">
                        {{ seconds === 0 ? "Reenviar Código" : `Reenviar Código em ${seconds} segundos` }}
                    </button>
                </form>
            </div>
        </div>
    </section>
</template>
