import { left, right, Either } from "~/lib/deps";
import { BaseError } from "../models/baseError";

export async function requestOtpCode(nif: string): Promise<Either<BaseError, void>> {
    const response = await $fetch("/api/request-otp", {
        method: "post",
        body: {
            nif: nif,
        },
    });

    if (response.status === "error") {
        return left({} as BaseError);
    }

    return right(undefined);
}
