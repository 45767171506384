import { left, right, Either } from "~/lib/deps";
import { BaseError } from "../models/baseError";

export async function validateOtp(nif: string, otp: string): Promise<Either<BaseError, void>> {
    const response = await $fetch("/api/validate-otp", {
        method: "post",
        body: {
            nif: nif,
            otp: otp,
        },
    });

    if (response.value?.message) {
        console.log(response.value.message);
        return left(response.value);
    }

    return right(undefined);
}
